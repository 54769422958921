import React from 'react'
import SignupButton from '../../components/SignupButton'
import Layout from '../../layouts/index'
import styles from './shared.module.css'

import Chrome from '../../components/Chrome'

import changelogImage from '../../../assets/changelog-portal-large.png'

const LearnChangelogPage = () => (
  <Layout>
    <div className="page">
      <div className={styles.hero}>
        <h1>Keep your users in the loop.</h1>
        <div className={styles.subtitle}>
          <p>
            Announce important news, product updates with a changelog service
          </p>
        </div>
        <Chrome>
          <img
            className={styles.heroImageChangelog}
            src={changelogImage}
            alt="screenshot of changelog"
          />
        </Chrome>
      </div>

      <div className={styles.featuresList}>
        <div className={styles.feature}>
          <div className={styles.description}>
            <h3>Schedule posts</h3>
            <p>
              Write new changelog entries to go out at a specific time in the
              future.
            </p>
          </div>
        </div>

        <div className={styles.feature}>
          <div className={styles.description}>
            <h3>Use Markdown</h3>
            <p>
              Easily format your posts the way you want them to look. Embed
              images, link, or videos that add value to your post.
            </p>
          </div>
        </div>

        <div className={styles.feature}>
          <div className={styles.description}>
            <h3>Tag posts</h3>
            <p>
              Indicate whether a post is related to an announcement, a bug fix
              or a new feature. Add your own custom tags for your specific
              product.
            </p>
          </div>
        </div>

        <div className={styles.feature}>
          <div className={styles.description}>
            <h3>Changelog widget</h3>
            <p>Embed the changelog as a widget directly in your app.</p>
          </div>
        </div>
      </div>

      <div className="signup-container">
        <SignupButton />
      </div>
    </div>
  </Layout>
)

export default LearnChangelogPage
